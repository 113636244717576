<template>
  <full-view :title="$t('user.ac')" back-button>
    <template v-slot:toolbar>
      <v-text-field
        class="d-none d-md-flex mx-1"
        v-model="search"
        append-icon="mdi-magnify"
        :label="$t('general.search')"
        single-line
        hide-details
        outlined
        background-color="white"
        dense
      ></v-text-field>
      <v-divider vertical class="mx-1" />
      <v-btn
        large
        depressed
        color="primary"
        @click="save"
      >
        {{ $t("general.save") }}
      </v-btn>
    </template>

    <v-card flat>
      <v-card-text class="overflow-y-auto px-0 pt-3" :style="getViewHeightStyle(56)">
        <v-form ref="form">
        <v-list dense>
          <v-list-item-group color="primary">
            <div v-for="(r, i) in filterRoles" :key="i">
              <v-divider v-if="i !== 0" />
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="text-uppercase">
                    {{ r.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ r.description }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-switch
                    v-model="selectedRoles"
                    :value="r.name"
                  />
                </v-list-item-action>
              </v-list-item>
            </div>
          </v-list-item-group>
        </v-list>

        <form-bar disable-validation @save="save" @reset="reset" />
      </v-form>
    </v-card-text>
  </v-card>
  </full-view>
</template>

<script>
import { mapGetters } from "vuex";
import FullView from "@/common/layouts/FullView";
import FormBar from "@/common/components/FormBar";

export default {
  name: "UserRoles",
  components: { FullView, FormBar },
  props: {
    item: {
      type: Object,
      required: true,
      default() {
        return {
          id: 0,
          roles: [],
        };
      },
    },
  },
  watch: {
    item: {
      handler: function(n) {
        if (n) {
          this.selectedRoles = n.roles;
        }
      },
    },
  },
  data() {
    return {
      search: null,
      selectedRoles: [],
      originalState: { id: 0 },
    };
  },
  mounted() {
    Object.assign(this.originalState, this.item);
  },
  computed: {
    ...mapGetters("roles", ["getRoles"]),

    filterRoles() {
      return this.search && this.notEmptyArray(this.getRoles)
        ? this.getRoles.filter((s) =>
            s.name.toLowerCase().includes(this.search.toLowerCase())
          )
        : this.getRoles;
    },

    changed() {
      return this.item && !this.compareObjects(this.item, this.originalState);
    },
    idParam() {
      return parseInt(this.$route.params.userId);
    },
  },
  methods: {
    save() {
      this.$emit("save", {
        id: this.idParam,
        roles: this.selectedRoles,
        keepOpen: this.keepOpen,
      });
    },
    reset() {
      Object.assign(this.item, this.originalState);
    },
  },
};
</script>
