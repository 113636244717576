<template>
  <v-container class="pa-0">
    <!-- <p class="pt-4">{{ params.body + ": [" + params.path + "]" }}</p> -->
    <v-toolbar outlined flat short dense>
      <v-chip-group style="max-width: 560px;">
        <v-btn tile depressed icon small @click="goToParent">
          <v-icon>mdi-folder-arrow-left-outline</v-icon>
        </v-btn>
        <v-divider vertical class="mx-1" />
        <v-chip
          v-for="(selected, i) in this.params.selection"
          :key="i"
          x-small
          label
          close
          @click:close="removeSelection(i)"
        >
          {{ selected }}
        </v-chip>
      </v-chip-group>
    </v-toolbar>
    <v-form ref="form">
      <v-row v-if="!isLoadingFilesTree" no-gutters>
        <v-col
          v-for="(file, i) in getFiles"
          :key="i"
          class="d-flex child-flex"
          cols="3"
        >
          <v-card tile outlined class="text-center">
            <v-sheet label small class="pa-1" color="grey lighten-3">{{
              file.name
            }}</v-sheet>
            
            <div class="pa-1">
              <v-btn
                x-large
                color="primary"
                v-if="!file.nodes && !isImage(file)"
                @click="addFile(file)"
                icon
              >
                <v-icon>
                  mdi-file
                </v-icon>
              </v-btn>
              <v-btn
                x-large
                color="orange"
                v-if="file.nodes"
                @click="openDirectory(file)"
                icon
              >
                <v-icon>
                  mdi-folder
                </v-icon>
              </v-btn>
              <v-img
                v-if="isImage(file)"
                :src="getPreview(file)"
                aspect-ratio="1"
                @click="addFile(file)"
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </div>
          </v-card>
        </v-col>

        <v-alert v-if="getFiles.length === 0" small color="yellow" class="body-2">
          <v-icon left>mdi-alert</v-icon>
          No image files found for this store yet.
        </v-alert>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "FilesManagerDialog",
  model: {
    prop: "params",
    event: "input",
  },
  props: {
    params: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      search: null,
    };
  },
  mounted() {
    this.path = this.params.path;
    this.loadFilesTree(this.path);
  },
  computed: {
    ...mapGetters("files", ["getFilesTree", "isLoadingFilesTree"]),

    getFiles() {
      const files = this.getFilesTree;
      return this.notEmptyArray(this.params.filter)
        ? files.filter((f) =>
            this.params.filter.some((s) => f.nodes || f.name.endsWith(s))
          )
        : files;
    },
  },
  methods: {
    ...mapActions("files", ["loadFilesTree"]),

    addFile(file) {
      if(!file) {
        return;
      }
      const a = this.params.path.replace(this.params.base + "/", "");
      const path = a ? `${a}/${file.name}` : file.name;
      if (!this.params.selection.some(s => s === path)) {
        if(!this.params.single) {
          this.params.selection = [...this.params.selection, path];
        } else {
          this.params.selection = [path];
        }
      }
    },
    openDirectory(file) {
      if (file && file.nodes) {
        this.params.path = file.path;
        this.loadFilesTree(this.params.path);
      }
    },
    goToParent() {
      if(this.params.base !== this.params.path) {
        const path = this.params.path.split("/");
        path.pop();
        this.params.path = path.join("/");
        this.loadFilesTree(this.params.path);
      }
    },
    isImage(file) {
      const imageFormats = [".jpg", ".png", ".gif", ".svg"];
      return (
        file &&
        !file.nodes &&
        file.name &&
        imageFormats.some((f) => file.name.indexOf(f) !== -1)
      );
    },
    getPreview(file) {
      return file && file.name
        ? `${this.getApiBaseUrl}/files/?path=${this.params.path}/${file.name}`
        : null;
    },
    removeSelection(i) {
      if(this.notEmptyArray(this.params.selection)) {
        this.params.selection = this.params.selection.filter((s, si) => si !== i)    }
      }
  },
};
</script>
