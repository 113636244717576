<template>
  <collapsable-card :title="title" flat>
    <div align="center">
      <v-avatar
        size="64"
        color="grey lighten-3"
        style="border: 3px solid #44444430"
        v-if="dir === 'avatars'"
      >
        <img :src="!!tempImage ? tempImage : getImage" />
      </v-avatar>
      <v-card
        color="grey lighten-3"
        flat
        style="overflow: hidden"
        v-if="dir !== 'avatars'"
      >
        <img
          height="64"
          contain
          :src="!!tempImage ? tempImage : getImage"
        />
      </v-card>
    </div>

    <v-divider class="my-2" />

    <div class="text-right mr-2">
      <v-btn v-if="store" depressed @click="selectImage">
        <v-icon>mdi-folder-multiple-image</v-icon>
        {{ $t("general.selectImage") }}...
      </v-btn>
    </div>

    <v-divider class="mt-2" />

    <v-file-input
      v-model="image"
      @change="imageChanged"
      @click:clear="clearImage"
      accept="image/png, image/jpeg, image/bmp"
      placeholder="Pick an image"
      append-icon="mdi-camera"
      :label="$t('general.imageFile')"
      show-size
      clearable
      clear-icon="mdi-image-off"
      solo
      outlined
      flat
      dense
      :append-outer-icon="image ? 'mdi-progress-upload' : undefined"
      @click:append-outer="uploadImage"
      class="mt-3"
    ></v-file-input>
  </collapsable-card>
</template>

<script>
import { mapActions } from "vuex";
import CollapsableCard from "./CollapsableCard";
import FilesManagerDialog from "@/common/dialogs/FilesManagerDialog";
export default {
  name: "ImageUpoader",
  components: { CollapsableCard, FilesManagerDialog },
  model: {
    prop: "path",
    event: "input",
  },
  props: {
    disabled: Boolean,
    title: String,
    dir: {
      type: String,
      default() {
        return "";
      },
    },
    keyAttr: String,
    path: String,
    store: Object,
  },
  data() {
    return {
      image: null,
      tempImage: null,
    };
  },
  computed: {
    getBasePath() {
      const rootDir = this.store ? `stores/_${this.store.uuid}/` : "";
      const baseDir = this.dir ? this.dir + "/" : "";
      return `${rootDir}${baseDir}`;
    },
    getFileName() {
      return this.keyAttr
        ? `_${this.keyAttr}.png`
        : this.path
        ? this.path
        : "default.png"
    },

    getImage() {
      return this.getServerImage(`${this.getBasePath}${this.getFileName}`);
    },
  },
  methods: {
    ...mapActions("ui", ["showDialog", "closeDialog"]),
    selectImage() {
      if(this.store) {
        const dialog = {
          title: this.$t("general.selectImage"),
          component: FilesManagerDialog,
          params: {
            base: `images/stores/_${this.store.uuid}${this.dir ? `/${this.dir}` : ""}`,
            path: `images/stores/_${this.store.uuid}${this.dir ? `/${this.dir}` : ""}`,
            selection: [],
            filter: [".jpg", ".png", ".gif", ".svg"],
            body: this.$t("messages.selectImages"),
            single: true,
          },
          width: 650,
          actions: [
            {
              text: this.$t("general.okay"),
              click: ({ selection }) => {
                if (this.notEmptyArray(selection)) {
                  this.$emit("input", selection[0]);
                }
                this.closeDialog();
              },
              color: "primary",
            },
            {
              text: this.$t("general.cancel"),
              click: () => this.closeDialog(),
              color: "primary",
            },
          ],
        };
        this.showDialog(dialog);
      }
    },

    imageChanged(file) {
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.tempImage = e.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        console.log("Old browser. No support for Filereader API");
      }
    },
    clearImage() {
      this.image = null;
      this.tempImage = null;
    },
    uploadImage() {
      if (this.image) {
        this.$upload(
          `/files/image/?path=${this.getImage}`,
          this.image
        )
          .then(() =>
            this.showFeedback({
              text: "messages.updateDone",
              color: "success",
            })
          )
          .catch(() =>
            this.showFeedback({
              text: "messages.updateNotSuccessful",
              color: "grey",
            })
          );
      }
    },
  },
};
</script>
