<template>
  <view-container>
    <user-form v-if="$route.name === 'user'" :item="item" @save="save" />

    <customer-form v-if="$route.name === 'customer'" :item="item" @save="save" />

    <user-roles v-if="$route.name === 'user.roles'" :item="item" @save="save" />

    <user-stores v-if="$route.name === 'user.stores'" :item="item" @save="save" />
  </view-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ViewContainer from "@/common/layouts/ViewContainer";
import UserForm from "./UserForm";
import CustomerForm from "../customers/CustomerForm";
import UserRoles from "./UserRoles";
import UserStores from "./UserStores";

export default {
  name: "UserEditor",
  components: { ViewContainer, UserForm, UserRoles, UserStores, CustomerForm },
  props: {
    type: String,
  },
  data() {
    return {
      item: {},
    };
  },
  computed: {
    ...mapGetters("users", ["getUserById"]),

    idParam() {
      return parseInt(this.$route.params.userId);
    },
    editMode() {
      return this.idParam > 0;
    },
  },
  mounted() {
    // in case user navigated directly to edit view load target item only
    if (this.editMode) {
      const item = this.getUserById(this.idParam);
      if (item) {
        this.item = item;
      } else {
        this.loadUsers().then(() => {
          this.item = this.getUserById(this.idParam);
        });
      }
    } else {
      this.item = { ...this.item, typeName: "CUSTOMER" };
    }
  },
  methods: {
    ...mapActions("users", ["loadUsers", "saveUser", "saveUserRoles", "updateAssignedStores"]),

    save(params) {
      if (this.$route.name === "user.roles") {
        const { id, roles } = params;
        this.saveUserRoles({ id, roles }).then(() => {
          this.$$router.back();
        });
      } else if (this.$route.name === "user.stores") {
        const { id, stores } = params;
        this.updateAssignedStores({ id, assignments: stores }).then(() => {
          this.$$router.back();
        });
      } else {
        const { item, editMode } = params;
        this.saveUser({ item, editMode }).then(() => {
          this.$$router.back();
        });
      }
    },
  },
};
</script>
