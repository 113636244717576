<template>
  <full-view :title="$t('user.assignedStores')" back-button>
    <template v-slot:toolbar>
      <v-text-field
        class="d-none d-md-flex mx-1"
        v-model="search"
        append-icon="mdi-magnify"
        :label="$t('general.search')"
        single-line
        hide-details
        outlined
        background-color="white"
        dense
      ></v-text-field>
      <v-divider vertical class="mx-1" />
      <v-btn
        large
        depressed
        color="primary"
        @click="save"
      >
        {{ $t("general.save") }}
      </v-btn>
    </template>

    <v-card flat>
      <v-card-text class="overflow-y-auto pa-0" :style="getViewHeightStyle(56)">
        <v-form ref="form">
        <v-list dense>
          <v-list-item-group color="primary">
            <div v-for="(store, i) in filterStores" :key="i">
              <v-divider v-if="i !== 0" />
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="text-uppercase">
                    {{ store.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ store.description }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-switch v-model="selectedStores" :value="store.id" />
                </v-list-item-action>
              </v-list-item>
            </div>
          </v-list-item-group>
        </v-list>

        <form-bar disable-validation @save="save" @reset="reset" />
        </v-form>
      </v-card-text>
    </v-card>
  </full-view>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FullView from "@/common/layouts/FullView";
import FormBar from "@/common/components/FormBar";

export default {
  name: "UserStores",
  components: { FullView, FormBar },
  props: {
    item: {
      type: Object,
      required: true,
      default() {
        return {
          id: 0,
          stores: [],
        };
      },
    },
  },
  data() {
    return {
      search: null,
      selectedStores: [],
      originalState: [],
    };
  },
  mounted() {
    this.loadAssignedStores(this.item.id).then(() => {
      const stores = this.getAssignedStores;
      if(this.notEmptyArray(stores)) {
        this.selectedStores = this.getAssignedStores.map((s) => s.id);
        Object.assign(this.originalState, this.selectedStores);
      }
    });
  },
  computed: {
    ...mapGetters("stores", ["getStores", "getAssignedStores"]),

    filterStores() {
      return this.search && this.notEmptyArray(this.getStores)
        ? this.getStores.filter((s) =>
            s.name.toLowerCase().includes(this.search.toLowerCase())
          )
        : this.getStores;
    },

    changed() {
      return this.item && !this.compareObjects(this.selectedStores, this.originalState);
    },
    idParam() {
      return parseInt(this.$route.params.userId);
    },
  },
  methods: {
    ...mapActions("stores", ["loadAssignedStores"]),

    save() {
      this.$emit("save", {
        id: this.idParam,
        stores: this.selectedStores,
        keepOpen: this.keepOpen,
      });
    },
    reset() {
      Object.assign(this.selectedStores, this.originalState);
    },
  },
};
</script>
