<template>
  <v-card class="mb-5" :outlined="compact || flat" :flat="compact || flat" :style="`border-color: ${getColor('darkSecondary')};`">
    <v-toolbar flat short dense height="40" color="secondary">
      <v-icon v-if="!!icon">
        {{ icon }}
      </v-icon>
      <v-toolbar-title>
        <span style="font-size: 12pt; font-weight: normal; color: #00000099;">{{ title }}</span>
      </v-toolbar-title>
      <v-spacer />
      <v-btn icon :small="!compact" :x-small="compact" @click="toggleCollapse" class="ma-0 white">
        <v-icon color="grey">
          {{ collapseToggled ? "mdi-chevron-down" : "mdi-chevron-up" }}
        </v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text v-show="!collapseToggled" :class="compact ? 'pa-0' : 'pa-1'">
      <slot></slot>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "CollapsableCard",
  data() {
    return {
      collapseToggled: false,
    };
  },
  props: {
    title: {
      required: true,
      default() {
        return "Title...";
      },
    },
    collapsed: Boolean,
    compact: Boolean,
    flat: Boolean,
    color: {
      default() {
        return "light-blue";
      },
    },
    icon: String,
  },
  watch: {
    collapsed: {
      handler: function(n) {
        this.collapseToggled = n;
      },
    },
  },
  mounted() {
    this.collapseToggled = this.collapsed;
  },
  computed: {
    ...mapGetters("ui", ["getColor"]),
  },
  methods: {
    toggleCollapse() {
      this.collapseToggled = !this.collapseToggled;
    },
  },
};
</script>
